import cx from 'classnames';
import useStyles from 'isomorphic-style-loader-react18/useStyles';
import { FC, HTMLAttributes, ReactNode } from 'react';
import { ValidationErrors } from 'src/types/validationTypes';
import FieldBar from './FieldBar';
import s from './FormGroup.less';

export const getErrorMessage = (
  field?: string,
  errors?: ValidationErrors | string | boolean | Record<string, string>,
): ReactNode => {
  if (typeof errors === 'string' && errors) return <p>{errors}</p>;
  if (
    field &&
    errors &&
    typeof errors === 'object' &&
    Object.prototype.hasOwnProperty.call(errors, field) &&
    errors[field] &&
    typeof errors[field] === 'string'
  ) {
    return <p>{errors[field]}</p>;
  }
  return '';
};

const FormGroup: FC<
  HTMLAttributes<HTMLDivElement> & {
    field?: string;
    error?: ValidationErrors | string | boolean;
    info?: string | ReactNode;
  }
> = ({ field = '', error = '', info = '', children, ...other }) => {
  useStyles(s);
  const errorMessage = getErrorMessage(field, error);
  return (
    <div className={s.root} {...other}>
      <div
        className={cx({
          formError: !!errorMessage || (typeof error === 'boolean' && error),
        })}
      >
        {children}
        <FieldBar error={!!errorMessage} />
      </div>

      <small
        className={s.errorMessage}
        style={{
          display: errorMessage ? 'block' : 'none',
        }}
      >
        {errorMessage}
      </small>

      <div className={s.info} style={{ display: info ? 'block' : 'none' }}>
        {info}
      </div>
    </div>
  );
};

export default FormGroup;
