import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getKeys } from 'customer/cacheKeys';
import { useEffect } from 'react';
import service from './service';
import { ResendRequest } from './types';

export function useConfirmVerificationCode({ token }: { token?: string } = {}) {
  const queryClient = useQueryClient();
  const { data, isPending, isError, isFetching } = useQuery({
    queryKey: getKeys.confirm(token || ''),
    queryFn: () => service.confirm({ token: token || '' }),
    enabled: !!token,
    staleTime: 3000,
    retry: false,
  });

  useEffect(() => {
    if (data) {
      queryClient.setQueryData(getKeys.authContext, data);
    }
  }, [data]);

  return {
    data,
    isPending,
    isError,
    isFetching,
  };
}

export function useResendVerificationCode() {
  return useMutation({
    mutationFn: (data: ResendRequest) => service.resend(data),
    mutationKey: getKeys.resend,
  });
}
