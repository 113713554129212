import bootstrapCss from 'bootstrap/dist/css/bootstrap-grid.min.css';
import useStyles from 'isomorphic-style-loader-react18/useStyles';
import React from 'react';
// external-global styles must be imported in your JS.
import normalizeCss from 'normalize.css';
import s from './LayoutCss.less';

const Layout = ({ children }: { children: React.ReactNode }) => {
  useStyles(normalizeCss, bootstrapCss, s);
  return <div>{children}</div>;
};

export default Layout;
