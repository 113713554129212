import { AuthContext } from '@monerium/sdk';
import restQuery from 'services/rest-query';
import { ConfirmRequest, ResendRequest } from './types';

const confirm = ({ token }: ConfirmRequest): Promise<AuthContext> =>
  restQuery.get(`/api/iam/confirm/${token}`);

const resend = (data: ResendRequest): Promise<{ success: boolean }> =>
  restQuery.patch(`/api/iam/confirm/email`, data);

export default {
  confirm,
  resend,
};
