import { keyPrefix } from 'providers/QueryProvider';
import { ChainUniqueName, EVMChainId } from 'types/emoney/Chain';
import { ExternalTokenSymbol } from 'types/emoney/Token';
import { ConvertRequest } from './components/emoney/Convert/types';

/**
 * Helper function to add an item to an array if it is defined
 * Can be beneficial for invalidating caches where you don't have access to the specifics of it.
 */
function addIfDefined<T>(item: T | undefined): T[] {
  return item !== undefined ? [item] : [];
}
const accountKeys = {
  accountList: keyPrefix(['account-list']),
  accountListWithoutBalances: keyPrefix(['account-list', 'without-balances']),
  accountListBalances: keyPrefix(['account-list', 'balances']),
  accountCreate: keyPrefix(['account-create']),
  accountRemove: keyPrefix(['account-remove']),
  accountUpdate: keyPrefix(['account-update']),
  accountSelect: keyPrefix(['account-select']),
  accountRead: (accountId: string) => keyPrefix(['account-read', accountId]),
};

const addressKeys = {
  isSmartContract: (address: string, chainId: EVMChainId) =>
    keyPrefix(['is-smart-contract', address, chainId]),
  isSafe: (address: string, chainId: number) =>
    keyPrefix(['is-safe', address, chainId]),
};

const emoneyKeys = {
  depositor: (chainName: ChainUniqueName, token: ExternalTokenSymbol) =>
    keyPrefix(['emoney-depositor', chainName, token]),
  permit: (chainName: ChainUniqueName) =>
    keyPrefix(['emoney-permit', chainName]),
  submitTransaction: (chainName: ChainUniqueName) =>
    keyPrefix(['emoney-submit-transaction', chainName]),
  convertCurrency: (params: Partial<ConvertRequest>) =>
    keyPrefix(['emoney-convert-currency', params]),
  rate: (params: Partial<ConvertRequest>) =>
    keyPrefix(['emoney-convert-rate', params]),
};

const orderKeys = {
  orderList: (accountId?: string) =>
    keyPrefix(['order-list', ...addIfDefined(accountId)]),
  orderCreate: keyPrefix(['order-create']),
  orderRead: (orderId: string) => keyPrefix(['order-read', orderId]),
};

const signatureKeys = {
  pendingOrderSignatures: (address?: string) =>
    keyPrefix(['pending-signatures', ...addIfDefined(address)]),
};

const treasuryAccountKeys = {
  treasuryList: keyPrefix(['treasury-list']),
  treasuryCreate: keyPrefix(['treasury-create']),
  treasuryUpdate: keyPrefix(['treasury-update']),
  treasuryRead: (accountId: string) => keyPrefix(['treasury-read', accountId]),
};

const personKeys = {
  personCreate: keyPrefix(['person-create']),
  personRemove: keyPrefix(['person-remove']),
  personUpdate: keyPrefix(['person-update']),
  personCreateVerifications: keyPrefix(['person-create-verifications']),
  personUpdateVerification: keyPrefix(['person-update-verification']),
  personRead: (personId: string) => keyPrefix(['person-read', personId]),
  personReadFromUser: (userId: string) =>
    keyPrefix(['person-read-userid', userId]),
};

const iamKeys = {
  signup: keyPrefix(['iam-signup']),
  authContext: keyPrefix(['iam-auth-context']),
  confirm: (token: string) => keyPrefix(['iam-confirm', token]),
  resend: keyPrefix(['iam-resend']),
  profile: keyPrefix(['iam-profile']),
  login: keyPrefix(['iam-login']),
};
const kycKeys = {
  kycMe: keyPrefix(['kyc-user-me']),
};

const helpKeys = {
  replySupportTicket: keyPrefix(['help', 'support-ticket-reply']),
  createSupportTicket: keyPrefix(['help', 'support-ticket-create']),
  listSupportTickets: keyPrefix(['help', 'support-ticket-list']),
  readSupportTicket: (id?: string) =>
    keyPrefix(['help', 'support-ticket-read', ...addIfDefined(id)]),
  readSupportTicketMessages: (id?: string) =>
    keyPrefix(['help', 'support-ticket-read-messages', ...addIfDefined(id)]),
  draftSupportTicket: keyPrefix(['help', 'support-ticket-draft']),
};

/**
 * React query cache keys
 */
export const getKeys = {
  ...addressKeys,
  ...accountKeys,
  ...emoneyKeys,
  ...orderKeys,
  ...signatureKeys,
  ...treasuryAccountKeys,
  ...personKeys,
  ...iamKeys,
  ...helpKeys,
  ...kycKeys,
};
