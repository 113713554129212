import cx from 'classnames';
import useStyles from 'isomorphic-style-loader-react18/useStyles';
import s from './FieldBar.less';

const FieldBar = ({ error = false }: { error: boolean }) => {
  useStyles(s);
  return (
    <span
      className={cx(s.bar, {
        [s.error]: error,
      })}
    />
  );
};

export default FieldBar;
