import cx from 'classnames';
import useStyles from 'isomorphic-style-loader-react18/useStyles';
import s from './EmailIcon.less';

function EmailIcon({
  color = 'default',
}: {
  color?: 'default' | 'success' | 'danger';
}) {
  useStyles(s);
  return (
    <div
      className={cx(s.root, {
        [s.success]: color === 'success',
        [s.danger]: color === 'danger',
      })}
    >
      <span
        style={{
          fontSize: '3rem',
          margin: '0',
        }}
        className={cx('material-icons', s.icon)}
      >
        mail_outline
      </span>
    </div>
  );
}

export default EmailIcon;
